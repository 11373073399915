import React, { Component } from 'react';
import ReactDOM from 'react-dom';

class PopUp extends Component {

    state = {id: Math.random().toString(36).substring(7),
        parentClass: this.props.parentClass || 'popup opened',
        contentClass: this.props.contentClass || 'popup__content',
        closeClass: this.props.closeClass || "popup__close"};

    open = () => {
        this.renderModal();
        document.body.style.top = `-${window.scrollY}px`;
        document.body.style.overflow = "hidden";
        document.body.style.position = 'fixed';
        document.body.style.width = '100%';
    };

    close = () => {
        if (this.props.onClose) { this.props.onClose(); }
        ReactDOM.render(null, this.portalElement);
        document.body.removeChild(this.portalElement);
        document.body.style.overflow = "unset";
        document.body.style.width = 'unset';
        this.portalElement = null;
        const scrollY = document.body.style.top;
        document.body.style.position = '';
        document.body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
    };

    componentWillUnmount() {
        if (!!this.portalElement) {
            document.body.removeChild(this.portalElement);
        }

    }

    renderModal = () => {
        let p = document.createElement('div');
        p.id = this.state.id;
        p.className = this.props.className || "";
        document.body.appendChild(p);
        this.portalElement = p;

        ReactDOM.render(<div className={this.state.parentClass}>
            <div className={this.state.contentClass}>

                {this.props.children}

                <a className={this.state.closeClass} onClick={this.close}>&times;</a>
            </div>
        </div>, this.portalElement);
    };

    render () {
        return null
    }


}

export default PopUp;