import React, {useEffect, useRef} from "react";
import PopUp from "../base/popup";
import FireworksLogo from "./ch-logo-fireworks.png";
import HoverButton from "../buttons/HoverButton";
import {setCookie} from "../cookies";

export default function PostUpdatePopup(props) {

    const postUpdatePopup = useRef(null);

    useEffect(() => {

        postUpdatePopup.current.open();
        setCookie('chpostuppp', 0, 1)

    }, []);

    return (
        <PopUp ref={postUpdatePopup} className="post-update-popup">
            <div className="grid-x align-center">
                <div className="small-12 cell">
                    <img src={FireworksLogo} alt={'We have made some changes!'} width="139" height="147"/>
                    <h3 className="post-update-popup__title">We’ve made some changes!</h3>
                    <p className="post-update-popup__text">Hi {props.name}, we are pleased to announce that CareerHunter has updated and we’ve made some notable improvements.</p>
                    <p className="post-update-popup__point"> <span className="post-update-popup__bullet"></span> Easier individual access to all our tests</p>
                    <p className="post-update-popup__point"> <span className="post-update-popup__bullet"></span> Additional and dedicated reports for each assessment</p>
                    <p className="post-update-popup__point"> <span className="post-update-popup__bullet"></span> Personalized insights with more intuitive analysis</p>
                    <p className="post-update-popup__point"> <span className="post-update-popup__bullet"></span> Improved and more relevant study suggestions</p>
                    <p className="post-update-popup__point"> <span className="post-update-popup__bullet"></span> Enhanced testing experience with many more upgrades</p>
                    <HoverButton href={'/dashboard'}>Explore Dashboard</HoverButton>
                </div>
            </div>
        </PopUp>
    )
}