import React, {Suspense } from "react";
const Slider = React.lazy(() => import(/* webpackChunkName: "trustPilotSlider" */ './lazy'));

export default function Index(props) {

    return (
        <Suspense fallback={<React.Fragment/>}>
            <Slider {...props} />
        </Suspense>

    )

}