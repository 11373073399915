import React from 'react';

export default function HoverButton(props) {

    const handleButtonOnHover = (e) => {
        const rect = e.target.getBoundingClientRect();
        const offset = {
            top: rect.top + window.scrollY,
            left: rect.left + window.scrollX,
        };
        const parentOffset = offset,
            relX = e.pageX - parentOffset.left,
            relY = e.pageY - parentOffset.top;
        if (e.target.querySelector('.button__hovered')) {
            e.target.querySelector('.button__hovered').style.top = relY + 'px';
            e.target.querySelector('.button__hovered').style.left = relX + 'px';
        }
    };
    const color = props.color || 'green';

    const classNames = ['button', `button--${color}`,`before-fade-in`,`fade-in`];
    if(props.className)
        classNames.push(props.className);

    if (props.span) {
        return <span className={classNames.join(' ')} onMouseEnter={handleButtonOnHover}>
            {props.children}
            <span className="button__hovered"/>
        </span>
    }

    if (props.href) {
        return <a className={classNames.join(' ')}
                          href={props.href} {...props.hrefOpts || {}}
                          onMouseEnter={handleButtonOnHover}>
            {props.children}
            <span className="button__hovered"/>
        </a>
    }

    return (
        <button className={classNames.join(' ')}
                onClick={props.onClick}
                style={props.style}
                onMouseEnter={handleButtonOnHover}>
            {props.children}
            <span className="button__hovered"/>
        </button>
    )
}