import throttle from "lodash/throttle";
import {isDevice} from "./utils";

export default function UtilsExitModal(props = {}) {
    const defaultOptions = {
        threshold: 1,
        maxDisplays: 1,
        eventThrottle: 200,
        onExitIntent: () => {},
    };

    return (function() {
        const config = { ...defaultOptions, ...props };
        const eventListeners = new Map();
        let displays = 0;
        let prevPositionY = 0;

        const addEvent = (eventName, callback) => {
            console.log('addEvent', eventName);
            document.addEventListener(eventName, callback, false);
            eventListeners.set(`document:${eventName}`, { eventName, callback });
        };

        const removeEvent = key => {
            const { eventName, callback } = eventListeners.get(key);
            document.removeEventListener(eventName, callback);
            eventListeners.delete(key);
        };

        const removeEvents = () => {
            eventListeners.forEach((value, key, map) => removeEvent(key));
        };

        const callExitIntent = () => {
            config.onExitIntent();
            displays++;
            if (displays >= config.maxDisplays) {
                removeEvents();
            }
        };

        // ----- START ----- for desktop -----
        const shouldDisplay = position => {
            let prevPosition = prevPositionY;
            prevPositionY = position;
            return (position <= config.threshold && prevPosition > position && displays < config.maxDisplays);
        };

        const mouseDidMove = event => {
            if (shouldDisplay(event.clientY)) {
                console.log("Desktop Mousemove Exit intent popup triggered");
                callExitIntent();
            }
        };

        // ----- END ----- for desktop -----

        if (isDevice()) {
            setTimeout(() => {
                callExitIntent();
            }, 60000);
        } else {

            setTimeout(() => {
                addEvent("mousemove", throttle(mouseDidMove, config.eventThrottle));
            }, 10000);
        }

        return removeEvents;
    })()
}