import React from 'react';
import HoverButton from "../buttons/HoverButton";

export default class GetStartedMenuButton extends React.Component {


    renderButton = () => {
        let user = this.props.user;
        let interestsStatus = user ? user.interests_status : localStorage.getItem(`interests_status`);
        if (!interestsStatus){ interestsStatus = 'not_started';}
        if (this.props.deleted){
            interestsStatus = 'not_started';
        }

        let userStatuses = user ? [user.interests_status, user.personality_status, user.motivators_status, user.abstract_status, user.verbal_status, user.numerical_status] : [];
        let anyStarted = user ? userStatuses.includes('started') || userStatuses.includes('finished') : interestsStatus !== 'not_started';
        let allFinished = user ? !userStatuses.includes('started') && !userStatuses.includes('not_started') : false;
        let hasFullAccess =  user ? user.full_access : false;
        let btnProps = {};
        if (this.props.className) {
            btnProps['className'] = this.props.className;
        }


        const getAccessLink = <div className={'buttons-line'}>
            <HoverButton className={'button--menu'} href={'/get-access'} {...btnProps}>Get Access</HoverButton>
            </div>


        const testLink = <HoverButton className={'button--menu'} href={'/tests'} {...btnProps}>
            {anyStarted ? <React.Fragment> <span className="hideText">Continue Testing</span><span className='hideForMediumUp'>Continue</span></React.Fragment> :
                <React.Fragment><span className="hideText">Take free test</span><span className='hideForMediumUp'>Start test</span></React.Fragment>}
        </HoverButton>;

        const testPaidLink = <HoverButton className={'button--menu'}  href={interestsStatus === 'finished' ? '/dashboard' : '/tests'} {...btnProps}>{anyStarted ?
            <React.Fragment><span className="hideText">Continue Testing</span> <span className ='hideForMediumUp'>Continue</span></React.Fragment> : 'Start testing' }</HoverButton>;

        const checkoutLink = <HoverButton className={'button--menu'} href={'/checkout'} {...btnProps}>
            Buy Access
        </HoverButton>;

        const extraPad = <span className="padR15"/>;
        switch (true) {
            case this.props.upgrade_page:
                return checkoutLink;
            case hasFullAccess && !allFinished:
                return testPaidLink;
            case hasFullAccess && allFinished:
                return extraPad;
            case interestsStatus !== 'finished':
                return getAccessLink;
            case interestsStatus === 'finished' && !hasFullAccess:
                return getAccessLink;
            default:
                return extraPad;
        }
    };

    render() {
       return  (this.renderButton())
    }




}
