import {actions} from 'react-fields'

class LoginApi {

    static getUser(user) {
        const url = '/api/sessions';

        let data = {email: user.email};

        return fetch(actions.postRequest(url, data))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            })
    }

    static beforeLoginUser(user) {
        const url = '/before-login';
        let data = {user: {email: user.email, password: user.password, remember_me: user.remember_me}};
        return fetch(actions.postRequest(url, data))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            })
    }

    static loginUser(user, saveAnswers = false) {
        const url = '/login';

        let data = {user: {email: user.email, password: user.password, remember_me: user.remember_me}};

        if (saveAnswers) {
            let testData = {};
            ['interests', 'personality', 'motivators'].map((testCode) => {
                if(localStorage.getItem(testCode)) {
                    let tempAnswers = localStorage.getItem(testCode);
                    tempAnswers = JSON.parse(tempAnswers);
                    let answers = [];
                    tempAnswers.filter((page) => page.answered ).map((page) => {
                        page.statements.map((statement) => {
                            answers.push({statement_id: statement.id, score: 5 - statement.order, page: page.page})
                        })
                    });
                    testData[`${testCode}_answers`] = answers;
                }
            });

            data.user.interests_status =  localStorage.getItem('interests_status') || 'not_started';
            data.user.personality_status =  localStorage.getItem('personality_status') || 'not_started';
            data.user.motivators_status =  localStorage.getItem('motivators_status') || 'not_started';
            data.interests_answers = testData.interests_answers;
            data.personality_answers = testData.personality_answers;
            data.motivators_answers = testData.motivators_answers;
        }


        return fetch(actions.postRequest(url, data))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            })
    }

    static resendAccountInstructions(user) {
        const url = `/api/sessions/${user.id}/resend_account_instructions`;
        return fetch(actions.getData(url))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            })
    }

    static recoverPassword(user, onSuccess, onError) {
        const url = '/password';
        let data = {user: {email: user.email}};
        return fetch(actions.postRequest(url, data))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            }).then(  (response) => {
            onSuccess();
        }).catch(error => {
            if (error.status === 422) {
                error.json().then(function (value) {
                    onError(value);
                });
            } else {
                console.error(error);
                onError();
            }
        });
    }

    static updatePassword(user, onSuccess, onError) {
        const url = '/password';
        let data = { user: {
                password: user.new_password,
                password_confirmation: user.password_confirmation || "",
                reset_password_token: user.reset_password_token
            }};

        fetch(actions.putRequest(url, data ))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            }).then( async (response) => {
            onSuccess();
        }).catch(error => {
            console.log(error);
            error.json().then(function (value) {
                onError(value);
            });
        });
    }

    static delete() {
        const url = `/login`;
        let data = {};

        return fetch(actions.deleteRequest(url, data ))
            .then(actions.status)
            .then(actions.json)

    }

}

export default LoginApi;