export const G_CAPTCHA_DISABLED = false;
export const MOBILE_BREAKEPOINT = 640;

export function disableCaptchaForTest() {
    let params = new URLSearchParams(location.search);
    return ((process.env.RAILS_ENV === 'development' || process.env.RAILS_ENV === 'staging') && !!params.get("tst") );
}

export const AGE_GROUPS = [{label: 'Under 18 years old', value: 'under_18'},
    {label: '18-24 years old', value:'from_18_to_24'},
    {label: '25-34 years old', value:'from_25_to_34'},
    {label: '35-44 years old', value:'from_35_to_44'},
    {label: '45-54 years old', value:'from_45_to_54'},
    {label: '55-64 years old', value:'from_55_to_64'},
    {label: '65 years or older', value:'from_65'},
    {label: 'I prefer not to say', value:'not_say_age'}];

export const GENDERS = [{label: 'Male', value:'male'},
    {label: 'Female', value:'female'},
    {label: 'Non-binary', value:'non_binary'},
    {label: 'I prefer not to say', value:'not_say'}];