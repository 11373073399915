import React, {useEffect,useState} from "react";

import {CURRENCY_COOKIE, setCookie, currentCurrency} from "../cookies";
import DropDown from '../base/customDropDown';

const CURRENCIES = [
    {name: 'USD $', mobileName: '$', value: 'USD'},
    {name: 'EUR €', mobileName: '€', value: 'EUR'},
    {name: 'GBP £', mobileName: '£', value: 'GBP'}
];

export default function CurrencyDropDown() {

    const [currency, setCurrency] = useState(currentCurrency);

    const onCurrencySelect = (selectedCurrency) => {
        setCookie(CURRENCY_COOKIE, selectedCurrency, 24);
        location.reload(true);
    };

    const [isGreaterThan640px, setIsGreaterThan640px] = useState(false);

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth > 720 || location.pathname === '/checkout') {
                setIsGreaterThan640px(true);
            } else {
                setIsGreaterThan640px(false);
            }
        }

        handleResize();

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    });

    return (
        <div className='currency-blk'>
            <DropDown onSelect={onCurrencySelect} selected={currency}
                      options={CURRENCIES} optionsValue='value' optionsLabel={isGreaterThan640px ? 'name' : 'mobileName'}
                      className="label-icon-right select-label currencies-select" optionClassName={'currencies-option'} />

        </div>
    );
}