import React, {Component} from "react";
import Slider from "react-slick";
import {FadeInSection} from "../../components/fadeIn";

export default class logoSlider extends Component {

    constructor(props) {
        super(props);
        let sliderSettings = {
            dots: false,
            arrows: false,
            speed: 600,
            cssEase:  'ease-out',
            slidesToShow: this.props.items.length < 6 ? this.props.items.length : 6,
            slidesToScroll: 1,
            className: 'featured-logos__slider',
            autoplay: true,
            autoplaySpeed: 2500,
            pauseOnHover: false,
            responsive: [
                {
                    breakpoint: 1440,
                    settings: {
                        slidesToShow: this.props.items.length < 5 ? this.props.items.length : 5,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 1025,
                    settings: {
                        slidesToShow: this.props.items.length < 4 ? this.props.items.length : 4,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: this.props.items.length < 3 ? this.props.items.length : 3,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 639,
                    settings: {
                        slidesToShow: this.props.items.length < 2 ? this.props.items.length : 2,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        this.state = {
            settings: this.props.settings ? {...sliderSettings, ...this.props.settings} : sliderSettings
        };
    }

    render() {
        return(
            <FadeInSection>
                <Slider {...this.state.settings}>
                    {this.props.items.map((logo) => {
                        return  <div className="featured-logos__slide" key={logo.id}>
                            <img src={logo.original} className="featured-logos__icon"  loading={'lazy'}/>
                        </div>
                    })}
                </Slider>
            </FadeInSection>
        );
    }
}