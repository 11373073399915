export const CURRENCY_COOKIE = 'chCurrency';
export const CURRENCY_DEFAULT_COOKIE = 'chDefaultCurrency';

export function setCookie(name, value, expirationHours) {
    if (expirationHours === undefined) {
        document.cookie = `${name}=${value}; path=/;`;
    } else {
        let expires = new Date();
        expires.setTime(expires.getTime() + (1 * expirationHours * 60 * 60 * 1000));
        document.cookie = `${name}=${value}; expires=${expires.toUTCString()}; path=/`;
    }
}

export function getCookieValue(cookieName) {
    let name = cookieName + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export function currentCurrency() {
    return getCookieValue(CURRENCY_COOKIE) || getCookieValue(CURRENCY_DEFAULT_COOKIE);
}