import React, {useRef, useEffect, useState} from 'react';

export const FadeInSection = ({children}) => {
    const domRef = useRef();

    const [isVisible, setVisible] = useState(false);

    const config = {
        root: null,
        rootMargin: '0px',
        threshold: [0, 1]
    };

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setVisible(true);
                    observer.disconnect();
                }
            });
        }, config);
        observer.observe(domRef.current);
        return () => observer.disconnect();
    }, []);

    return (<div ref={ domRef } className={ isVisible ? 'fade-in' : 'before-fade-in' }>{children}</div>);
};