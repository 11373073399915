import React, {Fragment, useState} from "react";
import LoginAPI from '../api/loginAPI';

const TESTS_LINKS = [
    {name: 'Career Interests', link: '/tests/interests'},
    {name: 'Work Personality', link: '/tests/personality'},
    {name: 'Career Motivators', link: '/tests/motivators'},
    {name: 'Abstract Reasoning', link: '/tests/abstract'},
    {name: 'Numerical Reasoning', link: '/tests/numerical'},
    {name: 'Verbal Reasoning', link: '/tests/verbal'},
    {name: 'See all tests', link: '/tests'}];

const EXPLORE_LINKS = [
    {name: 'Courses', link: '/courses'},
    {name: 'Jobs', link: '/jobs'}
];

export default function Overlay(props) {

    const [exploreExpanded, setExploreExpanded] = useState(false);
    const [testsExpanded, setTestsExpanded] = useState(false);

    const isUserLoggedIn = !!props.user;
    const hasFullAccess = isUserLoggedIn && props.user.full_access;

    const logout = async () => {
        LoginAPI.delete().then(() => {
            document.location.href = '/';
        }).catch(error => {
            console.log(error);
        });
    };

    const leftMenuLinkClass = 'overlay__link overlay__link--navigation disable-fade-in';
    const rightMenuLinkClass = 'overlay__link overlay__link--info';
    const rightMenuLinkClassBig = 'overlay__link overlay__link--account';

    const renderSubMenuItem = (item, index) => {
        return <li key={`submenuItem${index}`}><a href={item.link} className={'overlay__link'}>{item.name}</a></li>;
    };

    return(
        <nav className="text-center">
            <div className="grid-container">
                <div className={`grid-x align-middle overlay__submenu ${testsExpanded ? 'overlay__submenu--expanded' : ''}`}>
                    <div className={`medium-6 cell`}>
                        <div className={`grid-x align-middle overlay__submenu__content ${isUserLoggedIn ? 'overlay__submenu__content--login': ''}`}>
                            <div className={`cell`}>
                                <ul className={`overlay__list overlay__list--navigation`}>
                                    <a onClick={() => {setTestsExpanded(false)}} className={leftMenuLinkClass}><span className={`upgrade-icon caret-down left`}/>Tests</a>
                                    {TESTS_LINKS.map((item, index) => (renderSubMenuItem(item, index)))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`grid-x align-middle overlay__submenu ${exploreExpanded ? 'overlay__submenu--expanded' : ''}`}>
                    <div className={`medium-6 cell`}>
                        <div className="grid-x align-middle overlay__submenu__content">
                            <div className={`cell`}>
                        <ul className={`overlay__list overlay__list--navigation`}>
                            <a onClick={() => {setExploreExpanded(false)}} className={leftMenuLinkClass}><span className={`upgrade-icon caret-down left`}/>Explore</a>
                            {EXPLORE_LINKS.map((item, index) => (renderSubMenuItem(item, index)))}
                        </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid-x align-middle">
                    <div className="medium-6 cell">
                        <ul className="overlay__list overlay__list--navigation">
                            {isUserLoggedIn ?
                                    <li><a href="/dashboard" className={leftMenuLinkClass}>My Dashboard</a></li> : ''
                                }
                            <li className="disable-fade-in">
                                <a onClick={() => {setTestsExpanded(true)}} className={leftMenuLinkClass}>Our Tests <span className={`upgrade-icon caret-down right`}/></a>
                            </li>
                            {isUserLoggedIn ? null : <li className="disable-fade-in"><a href="/how" className={leftMenuLinkClass}>How it Works</a></li> }
                            <li className="disable-fade-in"><a href="/careers" className={leftMenuLinkClass}>Careers</a></li>
                            <li className="disable-fade-in">
                                <a onClick={() => {setExploreExpanded(true)}} className={leftMenuLinkClass}>Explore <span className={`upgrade-icon caret-down right`}/></a>
                            </li>
                            {hasFullAccess ? null : <li><a href="/upgrade" className={leftMenuLinkClass}>Full Access</a></li>}
                            {isUserLoggedIn ? null : <li className="disable-fade-in"><a href="/group" className={leftMenuLinkClass}>For Educators</a></li> }

                        </ul>
                    </div>
                    <div className="medium-6 cell flex-end">

                        <ul className="overlay__list overlay__list--account">
                            {isUserLoggedIn ? <Fragment>
                                <li>
                                    <a className={rightMenuLinkClassBig} onClick={logout}>Log Out<span className="small-icons logout" style={{marginLeft: '5px'}}></span></a>
                                </li>
                            </Fragment> :
                            <Fragment>
                                <li className="overlay__gutter"><a href="/register" className={rightMenuLinkClass}>Register</a> <span>/</span> <a href="/login" className={rightMenuLinkClass}>Log in <span className="small-icons login"></span></a></li>
                                {isUserLoggedIn ? <li><a href="/how" className={rightMenuLinkClass + " responsive"}>How it works</a></li> : '' }

                            </Fragment>}
                            {isUserLoggedIn ? <li><a href="/settings" className={isUserLoggedIn ? rightMenuLinkClass + " responsive" : rightMenuLinkClass }>Settings</a></li> : ''}
                            <li><a href="/faq" className={rightMenuLinkClass}>FAQs</a></li>
                            <li><a href="/about" className={rightMenuLinkClass}>About</a></li>
                            <li><a href="/contact" className={rightMenuLinkClass}>Contact</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    )

}